.PluginStorePage {
  &-grid {
    --grid-column-count: 2;
  }

  &-item {
    text-align: center;
    margin-bottom: 20px;

    &Card {
      --padding: calc(var(--marge-secondary) / 1.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: calc(var(--padding) / 1.5);
      overflow: hidden;
      padding: 20px;

      &::before {
        content: "";
        inset: 0 0 20%;
        background-color: rgb(var(--color));
        border-radius: 50%;
        filter: blur(50px);
        position: absolute;
        opacity: 0;
        transition: opacity var(--transition-card);
      }

      &:hover {
        &::before {
          opacity: 0.15;
        }

        .itemIcon {
          transform: scale(1.15);
        }
      }
    }

    &Icon {
      --size: 5em;
      transition: transform var(--transition-card);
    }

    &Title {
      font-size: 1.5em;
      font-weight: 600;
      font-family: var(--font-family-heading);
      margin-bottom: 0.25em;
    }

    &Install {
      color: var(--txt-secondary);
      font-size: 0.9em;

      span {
        color: var(--success);
      }
    }

    &Footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 5px;
    }
  }
}
