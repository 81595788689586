.DynamicInput {
  $el: &;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    #{$el} {
      max-width: 200px;
    }

    &-row {
      display: flex;
      gap: 24px;
    }
  }
}
