.TerminalPage {
  &-console {
    border: 0;
    border-radius: 1em;
    overflow: hidden;
    background-color: var(--bg-secondary);
    min-height: calc(400px + 50 * (100vw - 320px) / 1080);
  }
}


textarea {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100%;
  background-color: black;
  color: white;
}