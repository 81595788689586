@import './mixins/medias';

/* Variables */

:root {
	/* Transition */
	--cubic: cubic-bezier(.305, .045, .355, 1);
	--transition-link: .3s;
	--transition-input: .2s;
	--transition-card: .6s;

	/* Background */
	--opacity-primary: 4%;
	--opacity-secondary: 2%;
	--opacity-tertiary: 10%;

	--bg-body-rgb: 16 16 16;
	--bg-body: rgb(var(--bg-body-rgb));
	--bg-primary-rgb: 0 0 0;
	--bg-primary: rgb(var(--bg-primary-rgb));
	--bg-secondary-rgb: 255 255 255;
	--bg-secondary: rgb(var(--bg-secondary-rgb) / var(--opacity-primary));

	/* Txt */
	--txt-primary-rgb: 255 255 255;
	--txt-primary: rgb(var(--txt-primary-rgb));
	--txt-secondary-rgb: 255 255 255;
	--txt-secondary: rgba(var(--txt-secondary-rgb) / 40%);

	/* Color */
	--danger-rgb: 247 81 69; 
	--danger: rgb(var(--danger-rgb));
	--warning-rgb: 252 119 3;
	--warning: rgb(var(--warning-rgb));
	--success-rgb: 71 199 46; 
	--success: rgb(var(--success-rgb));
	--info-rgb: 125 44 212; 
	--info: rgb(var(--info-rgb));

	/* Marge */
	--marge-primary: calc(20px + 7 * (100vw - 320px) / 1080);
	--marge-secondary: calc(30px + 35 * (100vw - 320px) / 1080);
	--marge-grid: calc(15px + 5 * (100vw - 320px) / 1080);

	/* Border */
	--radius-primary: calc(15px + 5 * (100vw - 320px) / 1080);

	/* Shadow */
	--shadow-light: 0 0 calc(20px + 5 * (100vw - 320px) / 1080) rgb(var(--bg-secondary-rgb) / 5%) inset;
	--shadow-primary: 0 calc(10px + 5 * (100vw - 320px) / 1080) calc(20px + 5 * (100vw - 320px) / 1080) calc(-5px - 2 * (100vw - 320px) / 1080) rgb(var(--bg-primary-rgb) / 80%);
	
	/* Svg */
	--stroke-width-primary: 10px;
	--stroke-width-secondary: 7px;

	/* Sidebar */
	--sidebar-width: calc(270px + 50 * (100vw - 320px) / 1080);

	/* cpu mobile */
	--cpu-mobile_height: 85px;
	--cpu-mobile_logo: 100px;
	--cpu-mobile_padding: 10px;

	@include max($breakpoint-small) {
		--marge-secondary: calc(20px + 30 * (100vw - 320px) / 1080);
	}
}