@mixin styleTd {
  transition: background-color 0.2s;
  background-color: var(--bg-secondary);
  padding: calc(var(--padding) / 1.25) calc(var(--padding) / 2);
}

.WalletsTypesPage {
  --bg-hover: rgba(var(--bg-secondary-rgb) / 8%);

  td {
    transition: background-color 0.2s;
    background-color: var(--bg-secondary);
    padding: 2px 20px!important;
    vertical-align: middle;
  }

  td:first-child {
    border-radius: var(--radius) 0 0 var(--radius);
    padding-left: var(--padding);
  }

  td:last-child {
    border-radius: 0 var(--radius) var(--radius) 0;
    padding-right: var(--padding);
  }
}

.WalletsPage {
  --bg-hover: rgba(var(--bg-secondary-rgb) / 8%);

  td {
    @include styleTd;
    vertical-align: middle;
  }

  td:first-child {
    border-radius: var(--radius) 0 0 var(--radius);
    padding-left: var(--padding);
  }

  td:last-child {
    border-radius: 0 var(--radius) var(--radius) 0;
    padding-right: var(--padding);
  }

  &-table {
    --radius: 0.75em;
    --padding: 1.85em;
    width: 100%;
    overflow-x: auto;
    border-collapse: separate;
    border-spacing: 0 0.5em;
  }

  &-item {
    cursor: pointer;

    &.opened {
      td {
        background-color: var(--bg-hover);
      }

      td:first-child::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 0.5em solid var(--bg-hover);
        position: absolute;
        top: 100%;
        left: 50%;
      }
    }

    &-icon {
      color: var(--txt-secondary);
      font-size: var(--padding);
    }

    strong {
      display: block;
    }

    small {
      font-size: 0.7em;
      color: var(--txt-secondary);
    }

    .price {
      white-space: nowrap;
    }

    .showDetail {
      .Btn-btn {
        font-size: 0.8em;
      }
    }

    .chart {
      white-space: nowrap;

      &-info {
        display: flex;
        gap: 0.5em;
        color: rgb(var(--color));
      }
    }
  }

  &-wrapper {
    td {
      width: 100%;
      border-radius: 0 !important;
      background-color: transparent;
      padding: 0 !important;
    }

    &-content {
      @include styleTd;
      padding: calc(var(--padding) * 1.25);
      border-radius: var(--radius);
      display: flex;
      flex-direction: column;

      hr {
        border-top: 1px solid var(--bg-secondary);
      }

      .btn-group {
        margin: calc(var(--padding) * -0.25);
        margin-bottom: 0;
        justify-content: flex-end;

        & > * {
          flex: 1;
        }
      }
    }
  }
}


.keepix-logo {
  width: calc(150px + 50 * (100vw - 320px) / 1080);
  transition: transform 1s;

  svg {
    width: 100%;
    font-size: .5em;
    overflow: visible;

    use {
      animation: hoveredLogo 5s var(--cubic) infinite;
    }

    @keyframes hoveredLogo {
      from {
        transform: translateY(0%)
      }
      25% {
        transform: translateY(-20%)
      }
      50% {
        transform: translateY(0%)
      }
    }
  }
}

.AppBaseWelcome {
  font-size: calc(20px + 15 * (100vw - 320px) / 1080);
  font-weight: 600;
  font-family: var(--font-family-heading);
  text-align: center;
  margin-bottom: 20px;
}

.AppSubWelcome {
  font-size: calc(calc(20px + 15 * (100vw - 320px) / 1080) / 2);
  font-weight: 500;
  font-family: var(--font-family-heading);
  text-align: center;
  margin-bottom: 20px;
}