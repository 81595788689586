@import "../../scss/mixins/medias";

.AppBase {
  &-header {
    --title: calc(20px + 15 * (100vw - 320px) / 1080);
    --icon: calc(var(--title) * 1.75);
    --color: 255 255 255;
    display: flex;
    align-items: center;
    gap: calc(var(--title) / 1.5);

    &Icon {
      width: var(--icon);
      height: var(--icon);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--shadow-light);
      border-radius: calc(var(--icon) / 4);

      & > * {
        font-size: calc(var(--title) / 1.1);
      }
    }

    &Title {
      font-size: var(--title);
      font-weight: 600;
      font-family: var(--font-family-heading);
    }

    &Subtitle {
      font-size: 0.95em;
      color: var(--txt-secondary);

      @include max($breakpoint-xxs) {
        display: none;
      }
    }

    &Right {
      margin-left: auto;

      @include max($breakpoint-xxs) {
        .Btn-btn {
          padding: 0;
          width: 3em;
          height: 3em;

          span {
            display: none;
          }

          .iconify {
            margin-right: 0;
            opacity: 1;
            top: 0;
            transform: scale(1.2)
          }
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: var(--marge-grid);
  }
}
