@import "../../scss/mixins/styles";
@import "../../scss/mixins/medias";

.Form {
  /* Form */

  &-form {
    @include style-card;
    --padding: 3em;
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) / 1.5);

    @include max($breakpoint-xxs) {
      --padding: 2em;
    }

    @include max($breakpoint-mobile) {
      --padding: 1.75em;
    }
  }

  &-label {
    font-size: 0.95em;
    display: block;
    margin-bottom: 1em;

    small {
      opacity: 0.5;
      font-weight: normal;
      font-style: italic;
    }

    abbr {
      color: var(--info);
      text-decoration: none;
    }
  }

  &-input {
    --bg: var(--bg-secondary);
    --color: var(--txt-primary);
    --height: 3.5em;
    --gap: 1.25em;
    font-size: 1.1em;
    min-height: var(--height);
    border-radius: 0.65em;
    background-color: var(--bg);
    color: var(--color);
    display: flex;
    align-items: center;
    gap: var(--gap);
    padding: 0 var(--gap);
    pointer-events: none;

    &[data-disabled="true"] {
      background-color: rgba(255, 255, 255, 0.1);
    }

    input,
    select,
    textarea {
      flex: 1;
      color: currentColor;
      border: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      font-family: var(--font-family-main);
      pointer-events: auto;
      padding: 1.2em 0 1.25em;

      & + .iconify {
        right: 0;
        left: auto;
      }

      &[type="range"] {
        appearance: none;
        -webkit-appearance: none;
        --cursor: 20px;
        background-color: var(--bg-secondary);
        height: 10px;
        padding: 0;
        border-radius: 50px;

        &::-webkit-slider-thumb {
          @include style-cursor-range;
        }

        &::-moz-range-thumb {
          @include style-cursor-range;
        }

        &::-ms-thumb {
          @include style-cursor-range;
        }
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    select {
      border: none;
      outline: none;
    }

    select option {
      background-color: #262626;
      border: none;
    }

    .iconify {
      height: 100%;
      pointer-events: none;
      display: flex;
      align-items: center;
      transform: scale(1.15);

      &.show {
        pointer-events: auto;
        transition: var(--transition-input);

        &:not(:hover) {
          opacity: 0.5;
          cursor: pointer;
          z-index: 5;
        }
      }

      &:first-child {
        left: 0.1em;
      }
    }

    input::placeholder {
      color: currentColor;
      opacity: 0.35;
    }

    & + .label {
      margin-top: 2em;
    }

    & > span {
      transition: var(--transition-input);
      position: absolute;
      inset: 0;
      border: 2px solid var(--bg);
      border-radius: inherit;
      opacity: 0;
      pointer-events: none;
    }

    & > *:focus ~ span,
    & > *:hover ~ span {
      opacity: 1;
    }
  }

  &-checkbox {
    --size: 3.5rem;

    input {
      display: none;
    }

    &Case {
      --gutter: 5px;
      --cursor: calc(var(--size) * 1.25);
      width: calc(var(--size) * 2.8);
      height: var(--size);
      background-color: var(--bg-secondary);
      cursor: pointer;
      border-radius: var(--size);
      border: 2px solid transparent;
      display: flex;
      transition: var(--transition-input);
      user-select: none;

      &:hover {
        border-color: var(--bg-secondary);
      }

      &::before {
        content: "";
        position: absolute;
        width: var(--cursor);
        height: calc(var(--size) - var(--gutter) * 2);
        background-color: var(--bg-secondary);
        border-radius: var(--size);
        inset: calc(var(--gutter) - 2px);
        transition: var(--transition-input);
        z-index: 0;
      }

      span {
        position: absolute;
        display: flex;
        align-items: center;
        width: calc(var(--cursor) + var(--gutter));
        justify-content: center;
        height: 100%;
        font-size: 0.85em;
        text-align: center;
        z-index: 15;
        transition: var(--transition-input);
      }

      &Disabled {
        left: 0;
      }

      &Enabled {
        right: 0;
        font-weight: bold;
        color: var(--txt-secondary);
      }
    }

    input.active + &Case {
      background-color: rgba(var(--success-rgb) / calc(var(--opacity-primary) * 4));
      &::before {
        transform: translateX(70px);
        background-color: rgba(var(--success-rgb) / calc(var(--opacity-primary) * 4));
      }

      span {
        &.checkboxCaseDisabled {
          color: var(--txt-secondary);
        }
        &.checkboxCaseEnabled {
          color: var(--success);
        }
      }
    }
  }
}

.Form-input:has(input.invalid) {
  border: 1px solid red;
  margin: -1px;
}

.Form-input:has(input.valid) {
  border: 1px solid rgb(0, 255, 110);
  margin: -1px;
}
