.Popin {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &-mask {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &-close {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    cursor: pointer;
    background: var(--bg-secondary);
    border-radius: 8px;
    padding: 8px;
  }

  &-title {
    font-size: 1.5em;
    font-weight: 600;
    font-family: var(--font-family-heading);
    margin-bottom: 1em;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: #1d1d1d;
    text-align: center;
    min-width: 400px;
  }
}
