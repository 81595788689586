.SidebarPlugins {
  &-main {
    display: flex;
    flex-direction: column;
    gap: calc(var(--padding) / 1.25);
  }

  &-title {
    font-size: 0.7em;
    display: flex;
    text-align: center;
    text-transform: uppercase;
    gap: 0.5em;
    align-items: center;
    letter-spacing: 0.75em;

    &::before,
    &::after {
      background: linear-gradient(
        to right,
        transparent,
        currentColor,
        transparent
      );
      content: "";
      flex: 1;
      height: 1px;
      opacity: 0.15;
    }
  }

  &-list {
    margin-top: 16px;
  }

  &-empty {
    padding: 16px 0;
  }

  &-item {
    &Link {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease-out;

      &:hover,
      [data-active="true"] > & {
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--c-white);
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }

    &Content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &Title {
      font-weight: 700;
    }

    &Subtitle {
      font-size: 14px;
      font-weight: 100;
    }
  }
}