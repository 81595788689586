.Btn {
  &-btn {
    --opacity: var(--opacity-secondary);
    transition: background-color var(--transition-link),
      color var(--transition-link), transform 0.15s;
    font-weight: 700;
    background: rgba(var(--color, var(--bg-secondary-rgb)) / var(--opacity));
    color: rgb(var(--color, var(--bg-secondary-rgb)));
    padding: 1em 2em 1.1em;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    gap: 0.75em;
    user-select: none;

    
    &:not(:disabled) {
      &:hover {
        --opacity: var(--opacity-tertiary);
      }
    }

    &:active {
      transform: scale(0.95);
    }

    &:disabled {
      opacity: 0.5;
      cursor: inherit;
    }

    .iconify {
      top: 0.1em;
      margin-right: -0.5em;
      opacity: 0.5;
    }
  }
}
