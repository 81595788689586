@charset "UTF-8";

/* Reset */

*,
*::after,
*::before {
	position: relative;
	box-sizing: border-box;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: subpixel-antialiased;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, img, ol, ul, li, table, tbody, tfoot, thead, tr, th, td, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, output, audio, video, button, hr, input, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

a, button {
	cursor: pointer;
	color: inherit;
}

button {
	border: 0;
	padding: 0;
	background: none;
	display: inline-block;
}

a, a:hover, button, button:hover, *:focus, *:active {
	text-decoration: none;
	outline: none;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

strong {
	font-weight: bold;
}

img, svg {
	vertical-align: bottom;
	height: auto;
}

textarea {
	resize: none;
}

picture {
	display: block;

	img {
		max-width: 100%;
		width: 100%;
	}
}

small {
	font-size: .95em;
}

input {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type=search] {
		&::-ms-clear {
			display: none;
			width : 0; 
			height: 0; 
		}

		&::-ms-reveal { 
			display: none; 
			width : 0; 
			height: 0;
		}

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration { 
			display: none; 
		}
	}

	&[type=number] {
		appearance: textfield;
		-moz-appearance: textfield;
	}
}