@import "../../scss/mixins/styles";
@import '../../scss/mixins/medias';

.Sidebar {
  &-sidebar {
    --padding: var(--marge-primary);
    --gap: calc(var(--padding) / 1.5);
    width: var(--sidebar-width);
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: var(--padding);

    &Content {
      overflow-y: scroll;
      flex: 1;
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding: var(--padding) 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &Logo {
      @include style-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.4em 1.8em;

      & > * {
        width: 100%;
      }

      span {
        position: absolute;
        left: 40%;
        top: 65%;
        color: var(--txt-secondary);
        font-size: 9px;
        letter-spacing: 2px;
      }
    }
  }

  &-list {
    --gutter: calc(var(--padding) / 2);
    font-size: calc(13px + 1 * (100vw - 320px) / 1080);
    display: flex;
    gap: 1px;

    @include min($breakpoint-xl) {
      @include style-primary;
      padding: var(--gutter);
      flex-direction: column;

      & + & {
        margin-top: 10px;
      }
    }

    .active {
      background-color: var(--bg-secondary);
    }

    a {
      padding: 1em;
      border-radius: 0.85em;
      display: flex;
      align-items: center;
      gap: 1.25em;
      transition: var(--transition-link);

      @media (hover: hover) {
        &:hover {
          background-color: var(--bg-secondary);
        }
      }

      .iconify {
        transform: scale(1.4);
        color: var(--txt-secondary);
      }
    }
  }

  @include max($breakpoint-xl) {
    &-sidebar {
      padding: 0;
      width: var(--cpu-mobile_logo);
      padding: var(--cpu-mobile_padding);
      height: var(--cpu-mobile_height);
      flex-direction: row;

      &Content {
        display: none;
      }

      &Logo {
        padding: 0;
        width: 100%;
        height: 100%;
        justify-content: center;

        & > * {
          width: auto;
        }

        .Logo-logoTxt,
        span {
          display: none;
        }
      }
    }

    &-nav {
      border-radius: var(--radius-primary) var(--radius-primary) 0 0;
      box-shadow: var(--shadow-light), var(--shadow-primary);
      background-color: var(--bg-body);
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      display: flex;
      padding: 5px;
      gap: 1px;

      span {
        display: none;
      }
    }

    &-list {
      font-size: calc(17px + 2 * (100vw - 320px) / 1080);

      .active {
        .iconify {
          color: var(--txt-primary);
        }
      }
    }
  }
}
